<template>
  <section>
    <b-row class="match-height"> </b-row>
  </section>
</template>

<script>
import { BRow } from "bootstrap-vue";
export default {
  components: {
    BRow,
  },
  data() {
    return {};
  },
  mounted() {
    this.checkAuth();
},
  // mounted: function () {
  //   this.checkAuth();
  //   console.log("App version: " + process.env.VUE_APP_BACKEND_URL);
  //   console.log("Environment: " + process.env.NODE_ENV);
  // },
  methods: {
    checkAuth() {
      // CALLBACK
      const token = localStorage.getItem("token");
      if (!token) {
        return router.push({
          name: "login",
        });
      }
      this.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "user")
        .then((response) => {
          if (response.data != null) {
            this.cek_user = response;
            localStorage.setItem("Unama", response.data.nama);
            localStorage.setItem("Uemail", response.data.email);
            localStorage.setItem("Ulevel", response.data.level);
            localStorage.setItem("Ukampus", response.data.kampus);
            localStorage.setItem("Uid", response.data.id);
            this.getLevel = response.data.level;
            console.log("cek level", this.getLevel);
            if (this.getLevel == 0) {
              window.location.href = process.env.VUE_APP_FRONTEND_URL + "user/dashboard"
              // this.$router.push({ name: "dashboard_user" });
            } else if (this.getLevel == 1) {
              window.location.href = process.env.VUE_APP_FRONTEND_URL + "user/dashboard"
              // this.$router.push({ name: "dashboard_user" });
            } else if (this.getLevel == 2) {
              window.location.href = process.env.VUE_APP_FRONTEND_URL + "admin/dashboard"
              // this.$router.push({ name: "dashboard_admin" });
            }
          } else {
            this.$router.push({ name: "login" });
          }
        });
    },
  },
};
</script>
